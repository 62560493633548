.mainContainer {
    top: 0px;
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 15px 100px 15px;
    padding: 0px 15px 0px 15px;
    width: 315px;
    font-family: 'Montserrat';
    font-style: normal;
    color: 'gray';
    letter-spacing: -0.24px;
    line-height: 1.4;
    height: 250px;
    overflow:scroll;
    overflow-x:hidden;
}

.mainTitle {
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    padding: 15px;
}

.boldContent {
    font-weight: 600;
    font-size: 9px;
    letter-spacing: -0.24px;
    line-height: 1.4;
}

.content {
    font-size: 9px;
    padding-bottom: 16px;
    letter-spacing: -0.24px;
    line-height: 1.4;
}

.table {
    width: 285px;
    background: white;
    border: 1px solid #F2F2F2;
}

.tableRow {
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
}

.tableCellBold {
    font-weight: 600;
    font-size: 9px;
    letter-spacing: -0.24px;
    line-height: 1.4;
    padding-left: 5px;
}

.tableCell {
    padding: 2px;
    font-size: 9px;
    letter-spacing: -0.24px;
    line-height: 1.4;
    border: 1px solid #F2F2F2;
}

.apr {
    font-weight: 600;
    font-size: 16px;
}

::-webkit-scrollbar {
    width:5px;
    background: #F2F2F2;
    border-radius: 25px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #F2F2F2;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb {
    background: #D6D8E1;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background: #07C0CA;
}





