.logo {
  display: block;
  width: 65px;
  margin: 10px auto 30px;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
}

.button {
  border-radius: 2px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.buttonContainer {
  padding-left: 15px;
  padding-right: 15px;
}

.bottom {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}
