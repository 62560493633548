.loader {
  display: block;
  margin: 30px auto;
  height: 64px;
  padding-left: 5px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
}

.animatedMessage {
  text-align: center;
  font-size: 14px;
}

.animatedMessage::after {
  content: "No hidden fees";
  animation: phraseChange 12s infinite;
}

@keyframes phraseChange {
  0% {
    content: "No hidden fees";
  }
  15% {
    content: "No hidden fees";
  }
  34% {
    content: "Clear terms, no tricks";
  }
  56% {
    content: "100% paperless";
  }
  78% {
    content: "Customers love us";
  }
  100% {
    content: "No hidden fees";
  }
}