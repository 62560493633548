.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0px !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

div.button:hover {
  color: #006f7b !important;
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.verificationMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  padding-top: 1rem;
}

.options {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #6f7595;
  margin-bottom: 25px;
  padding-left: 1rem;
}

.pageHeader {
  text-align: center;
}

.consumerPageWrapper {
  max-width: 315px;
}

.buttonRow {
  margin-top: 13px;
}