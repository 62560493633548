.logo {
  display: block;
  margin: 30px auto 30px;
}

.message {
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-bottom: 15px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
  margin-bottom: 15px;
}
