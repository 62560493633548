.mainContainer {
    width: 315px;
    height: 130px;
    font-family: 'Montserrat';
    font-style: normal;
    text-align: center;
    background: #EAFAFB;
    border-radius: 4px;
    padding: 0px 30px 20px 30px;
    margin: 15px;
}

.legend {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.24px;
    color: #31364C;
    padding: 20px 30px 20px 30px;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    color: #31364C;
    padding-bottom: 3px;
}

.apr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 27px;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.33px;
    color: #FFFFFF;
    background: #FFAE1A;
    border-radius: 32px;
    margin: auto;
}