.modal {
  background-color: rgba(49, 54, 76, 0.65);
}

.modalDialog {
  border-radius: 8px !important;
  background-color: #ffffff;
  max-width: 315px !important;
  margin: 100px auto !important;

  .modalContent {
    border-radius: 8px !important;
    box-shadow: unset !important;

    .modalHeader {
      border-bottom: none;
      padding: 24px 20px 0 60px;

      button {
        margin-left: 0px !important;
      }

      img {
        width: 50px;
        margin: 6px auto 0 auto;
      }
    }

    .modalBody {
      overflow: hidden;
      max-height: 65vh;
      max-width: 100%;
      padding-bottom: 40px;
      padding-top: 15px;

      p {
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #787e9c;
        margin-bottom: 15px;
      }

    }
  }
}