.logo {
  display: block;
  width: 45px;
  margin: 10px auto 30px;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
