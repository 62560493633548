.image {
  display: block;
  width: 32px;
  height: 32px;
}

.h5 {
  font-size: 18px;
  font-weight: bold;
  color: #31364c;
  margin-top: 0;
}

.next {
  text-align: center;
  margin-bottom: 54px;
  margin-top: 0;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #31364c;
    margin: 0;

  }

  div {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    color: #787e9c;
  }

  p {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
  }

  .innerText {
    text-align: left;
    margin-left: 30px;
    margin-right: 15px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #52576F;
    padding: 0;

    li {
      margin-top: 10px;
      list-style-type: disc;
      list-style-position: inside;
      text-indent: -1.5em;
    }
    .learnMore {
      cursor: pointer;

      b {
        color: #00c3cc;
      }
    }
  }
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.box{
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.support {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  border: 1.3px solid #07C0CA;
  border-radius: 2px;
  padding: 20px;
  h3 {
    font-size: 14px;
    font-weight: bold;
    color: #52576F;
    padding: 0;
    margin: 0;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  span {
    cursor: pointer;
    b {
      color: #00c3cc;
    }
  }
}

.autoPaymentsBox {
  @extend .box;
}

.autoPaymentsMessage {
  @extend .box;
  color: #00c3cc !important;
  text-transform: uppercase;
  padding-top: 23px;
  padding-bottom: 23px;
}

.button {
  border-radius: 2px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}
